import React, { useState } from 'react';
import randomstring from 'randomstring';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';


const App = () => {
  const [passwordLength, setPasswordLength] = useState(12);
  const [generatedPassword, setGeneratedPassword] = useState('');
  const lowercaseLatters = 'abcdefghijklmnopqrstuvwxyz';
  const uppercaseLatters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';

  const handleGenerateSimplePassword = () => {
    const charset =  `${lowercaseLatters}${uppercaseLatters}${numbers}`;
    
    if (!passwordLength) {
      toast.error('કઈ ક તો સિલેક્ટ કર'); 
      return;
    }
    else if (passwordLength < 8 )
    {
      toast.error('SORRY શક્તિમાન, નાનો પાસવર્ડ ');
      return;
    }
    else if (passwordLength > 64 )
    {
      toast.error('એટલો મોટો પાસવર્ડ યાદ નઈ રહે');
      return;
    }

    const password = randomstring.generate({
      length: passwordLength,
      charset: charset
    });

    // Copy the password to the clipboard
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = password;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    setGeneratedPassword(password);
  };

  const handleGenerateStrongPassword = () => {
    const charset =  `${lowercaseLatters}${uppercaseLatters}${numbers}!@#$%^&*()_+{}[]<>?|~`;

    if (!passwordLength) {
      toast.error('કઈ ક તો સિલેક્ટ કર');
      return;
    }
    else if (passwordLength < 8 )
    {
      toast.error('SORRY શક્તિમાન, નાનો પાસવર્ડ ');
      return;
    }
    else if (passwordLength > 64 )
    {
      console.log(passwordLength)
      toast.error('એટલો મોટો પાસવર્ડ યાદ નઈ રહે');
      return;
    }

    const password = randomstring.generate({
      length: passwordLength,
      charset: charset
    });

    // Copy the password to the clipboard
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = password;
    tempInput.select();
    document.execCommand('copy');
    document.body.removeChild(tempInput);

    setGeneratedPassword(password);
  }

  return (
    <>
    <div className='main'>
      <div className="container">
        <h1>મજબૂત પાસવર્ડ બનાવો</h1>
        <div>
          <label>Password Length:</label>
          <input
            type="number"
            value={passwordLength}
            min={4}
            max={128}
            onChange={(e) => setPasswordLength(Number(e.target.value))}
            style={{ width: '200px', fontSize: '18px', padding: '5px' }}
          />
        </div>

        <button onClick={() => handleGenerateSimplePassword(false)} style={{ fontSize: '20px' }}>
  સાદો પાસવર્ડ
</button>
<button onClick={() => handleGenerateStrongPassword(false)} style={{ fontSize: '20px' }}>
મજબૂત પાસવર્ડ
</button>
      </div>
      <div className='generated-password'>
    {Boolean(generatedPassword.length) && <p style={{ fontSize: '24px', fontWeight: 'bold' }}>
          પાસવર્ડ કોપી થયો છે ભાઈ: {generatedPassword}
        </p>}
        </div>
    <div>
      
    </div>
    <div className="marquee"> <span>આ તો તારા ભાઈ એ બનાવ્યું છે </span></div>
    <ToastContainer />
    </div>
    </>

  );
};

export default App;

// code end